import {defineStore} from 'pinia'
import {isEqual} from 'lodash'
import {useNuxtApp} from 'nuxt/app'
import {useNotification} from '@/composables/useNotification'
import {computed, ref} from 'vue'
import {$lara, $larafetch} from '@/utils/$larafetch'
import {useFormRulesStore} from "@/store/formRules";

export const useFormProcessStore = defineStore('formProcess', () => {
    const formProcessData = ref(null)
    const initialFormProcessData = ref(null)
    const allProcesses = ref(null)
    const formProcessLoaded = ref(false)
    const formProcessDoesNotExist = ref(false)
    const signatureLink = ref(null)
    const signatureLinkLoading = ref(false)
    const formProcessState = ref('editable')

    const formProcessDirty = computed(
        () =>
            formProcessData.value &&
            initialFormProcessData.value &&
            !isEqual(formProcessData.value, initialFormProcessData.value)
    )

    function storeFormProcessData(response) {
        formProcessData.value = {...response.data}
        initialFormProcessData.value = {...response.data}
        allProcesses.value = response.processes
        formProcessLoaded.value = true
        formProcessDoesNotExist.value = false

        if (!formProcessData.value.status.edit_application_section) {
            formProcessState.value = 'disabled'
        }
        useFormRulesStore().storeFormRulesData()
    }

    function handleRequestError(error) {
        const toast = useNotification()
        const app = useNuxtApp()
        formProcessLoaded.value = true
        if (error && error.response && error.response.status === 422) {
            toast.error(app.$i18n.t('invalidRequestData') + '\n' + error.response._data.message)
        } else if (error && error.response && error.response.status === 404) {
            formProcessDoesNotExist.value = true
            toast.error(app.$i18n.t('modelDoesNotExist'))
        }
        throw error
    }

    async function fetchFormProcessData(id) {
        formProcessState.value = 'editable'
        formProcessLoaded.value = false
        await $larafetch(useNuxtApp().$apiRoute('spa.processes.formProcesses.show', {id}))
            .then(storeFormProcessData)
            .catch(handleRequestError)
    }

    async function saveFormProcessData(status = 'draft') {
        formProcessLoaded.value = false

        await $lara
            .put(useNuxtApp().$apiRoute('spa.processes.formProcesses.update', {formProcess: formProcessData.value.id}), {
                body: {
                    ...formProcessData.value,
                    newStatus: status
                },
            })
            .then((response) => {
                const toast = useNotification()
                const app = useNuxtApp()

                toast.success(app.$i18n.t('formProcesses.saveSuccess'))
                storeFormProcessData(response)
            })
            .catch(handleRequestError)
    }

    async function startSignatureProcess(status = 'draft') {
        signatureLinkLoading.value = true
        await $lara
            .put(useNuxtApp().$apiRoute('spa.processes.formProcesses.startSignatureProcess', {formProcess: formProcessData.value.id}), {
                body: {
                    ...formProcessData.value,
                    newStatus: status
                },
            })
            .then((response) => {
                signatureLink.value = response.signatureLink
                signatureLinkLoading.value = false
            })
            .catch((error) => {
                signatureLinkLoading.value = false
                handleRequestError(error)
            })
    }

    async function getSignature() {
        await $lara
            .get(useNuxtApp().$apiRoute('spa.processes.formProcesses.getSignature', {formProcess: formProcessData.value.id}))
            .then((response) => {
                formProcessData.value.sign_image = response
                if (response.length > 0) {
                    formProcessState.value = 'signed'
                }
            })
            .catch((error) => {
                handleRequestError(error)
            })
    }

    function resetData() {
        formProcessData.value = null
        initialFormProcessData.value = null
        formProcessLoaded.value = false
        formProcessDoesNotExist.value = false
        signatureLink.value = null
        signatureLinkLoading.value = false
        formProcessState.value = 'editable'
    }

    return {
        formProcessData,
        allProcesses,
        formProcessLoaded,
        formProcessDirty,
        formProcessDoesNotExist,
        signatureLink,
        signatureLinkLoading,
        formProcessState,
        fetchFormProcessData,
        resetData,
        saveFormProcessData,
        startSignatureProcess,
        getSignature,
    }
})
