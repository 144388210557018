import {defineStore} from 'pinia'
import {useFormProcessStore} from './formProcess'
import {ref} from 'vue'

export const useFormRulesStore = defineStore('formRules', () => {
    const formIdentifier = ref(null)
    const formRulesInitial = ref({})
    const formRules = ref({})
    const formFieldsRequired = ref({})
    const formFieldsWithWildcardInRule = ref([])

    function storeFormRulesData() {
        formIdentifier.value = useFormProcessStore().formProcessData.form.identifier
        formRulesInitial.value = useFormProcessStore().formProcessData.rules

        getFormRules()
        getRequiredFields()
    }

    function getFormRules() {
        Object.keys(formRulesInitial.value).forEach((fieldIdentifier) => {
            const rules = handleRules(fieldIdentifier)

            formRules.value[fieldIdentifier] = rules.join('|')
        })
    }

    function getRequiredFields() {
        Object.keys(formRulesInitial.value).forEach((fieldIdentifier) => {
            formFieldsRequired.value[fieldIdentifier] = formRulesInitial.value[fieldIdentifier].split('|').includes('required')
        })
    }

    function updateFormRules() {
        getFormRules()
    }

    function handleRules(fieldIdentifier) {
        let rules = formRulesInitial.value[fieldIdentifier].split('|')

        const isNumberField = formRulesInitial.value[fieldIdentifier].includes('numeric') ||
            formRulesInitial.value[fieldIdentifier].includes('integer') ||
            formRulesInitial.value[fieldIdentifier].includes('decimal')

        rules.forEach((rule, index) => {
            rule = replaceFieldIdentifiersInRule(rule, isNumberField)

            rule = calculateRule(rule)

            rules[index] = rule
        })

        rules = handleDuplicatedRules(rules)

        return rules
    }

    function replaceFieldIdentifiersInRule(rule, isNumberField) {
        const formProcessData = useFormProcessStore().formProcessData

        while(rule && rule.includes('{')) {
            const replaceFieldIdentifier = rule.substring(rule.indexOf('{') + 1, rule.indexOf('}'))

            if (!formFieldsWithWildcardInRule.value.includes(replaceFieldIdentifier))
                formFieldsWithWildcardInRule.value.push(replaceFieldIdentifier)

            if (formProcessData.data[replaceFieldIdentifier] !== null) {
                let replaceValue = formProcessData.data[replaceFieldIdentifier] || 0

                if (isNumberField) replaceValue = replaceValue.toString().replace(',', '.')

                rule = rule.replace('{' + replaceFieldIdentifier + '}', replaceValue)
            } else {
                rule = null
            }
        }

        return rule
    }

    function calculateRule(rule) {
        let calc = null

        if (rule.includes('+') || rule.includes('-') || rule.includes('*') || rule.includes('/')) {
            calc = rule.substring(rule.indexOf(':') + 1)
        }

        if (calc) {
            rule = rule.replace(calc, eval(calc.replace(',', '.')))
        }

        return rule
    }

    function handleDuplicatedRules(rules) {
        const rulesKeyedByRuleName = {}
        rules.forEach(rule => {
            const ruleName = (rule.indexOf(':') >= 0) ? rule.substring(0, rule.indexOf(':')) : rule
            if (!(ruleName in rulesKeyedByRuleName)) rulesKeyedByRuleName[ruleName] = []
            rulesKeyedByRuleName[ruleName].push(rule.replace(ruleName + ':', ''))
        })

        Object.keys(rulesKeyedByRuleName).forEach(ruleName => {
            if (rulesKeyedByRuleName[ruleName].length > 1) {
                let value = null

                switch (ruleName) {
                    case 'max_value':
                    case 'max':
                        rulesKeyedByRuleName[ruleName].forEach((rule, index) => {
                            rulesKeyedByRuleName[ruleName][index] = parseFloat(rule)
                        })

                        value = Math.min(...rulesKeyedByRuleName[ruleName])
                        break
                    case 'min_value':
                    case 'min':
                        rulesKeyedByRuleName[ruleName].forEach((rule, index) => {
                            rulesKeyedByRuleName[ruleName][index] = parseFloat(rule)
                        })

                        value = Math.max(...rulesKeyedByRuleName[ruleName])
                        break
                    default:
                        value = rulesKeyedByRuleName[ruleName][0]
                }

                rulesKeyedByRuleName[ruleName] = [value]
            }
        })

        rules = []

        Object.keys(rulesKeyedByRuleName).forEach(ruleName => {
            if (ruleName === rulesKeyedByRuleName[ruleName][0]) {
                rules.push(ruleName)
            } else {
                rules.push(ruleName + ':' + rulesKeyedByRuleName[ruleName][0])
            }
        })

        return rules
    }

    function resetData() {
        formIdentifier.value = null
        formRulesInitial.value = {}
        formRules.value = {}
        formFieldsRequired.value = {}
        formFieldsWithWildcardInRule.value = []
    }

    return {
        formIdentifier,
        formRulesInitial,
        formRules,
        formFieldsRequired,
        formFieldsWithWildcardInRule,
        storeFormRulesData,
        updateFormRules,
        resetData
    }
})
